import { bankidConstants, routesConstants } from "../constants";
import { bankidService, loginService } from "../services";
import { history } from "../helpers";
import { setTokenLocalStorage } from "./functions";
import snackbarActions from "./snackbar.actions";
import { authSelectors } from "../selectors";
import { timeout } from "../helpers/utils";

const { HOME, LOGIN, BANKID_COLLECT, BANKID_REDIRECT } = routesConstants;
const { isOpenBankIdPending, getLoadingByType, isUserLoggedIn } = authSelectors;

const requestCollect = (collectResponse) => ({
  type: bankidConstants.COLLECT_REQUEST,
  collectResponse,
});
const collectSuccess = (collectResponse) => ({
  type: bankidConstants.COLLECT_SUCCESS,
  collectResponse,
});
const collectFailure = (error) => ({
  type: bankidConstants.COLLECT_FAILURE,
  error,
});
const saveQrCode = (qrCode) => ({
  type: bankidConstants.SAVE_QR_CODE,
  qrCode,
});

const requestCancel = (tokenResponse) => ({
  type: bankidConstants.CANCEL_REQUEST,
  tokenResponse,
});
const cancelSuccess = (tokenResponse) => ({
  type: bankidConstants.CANCEL_SUCCESS,
  tokenResponse,
});
const cancelFailure = (error) => ({
  type: bankidConstants.CANCEL_FAILURE,
  error,
});

const loginSuccess = () => ({
  type: bankidConstants.LOGIN_SUCCESS,
});

const requestAuth = (authResponse) => ({
  type: bankidConstants.AUTHENTICATE_REQUEST,
  authResponse,
});

const authSuccess = (authResponse) => ({
  type: bankidConstants.AUTHENTICATE_SUCCESS,
  authResponse,
});

const requestQrCodeAuth = (authResponse) => ({
  type: bankidConstants.AUTHENTICATE_QRCODE_REQUEST,
  authResponse,
});

const authQrCodeSuccess = (authResponse) => ({
  type: bankidConstants.AUTHENTICATE_QRCODE_SUCCESS,
  authResponse,
});

const authQrCodeFailure = () => ({
  type: bankidConstants.AUTHENTICATE_QRCODE_FAILURE,
});

const authFailure = () => ({
  type: bankidConstants.AUTHENTICATE_FAILURE,
});

const openBankIdPending = () => ({
  type: bankidConstants.OPEN_APP_PENDING,
});
const openBankIdSuccess = (autoStartToken) => ({
  type: bankidConstants.OPEN_APP_SUCCESS,
  autoStartToken,
});
const openBankIdFailure = () => ({
  type: bankidConstants.OPEN_APP_FAILURE,
});

const cancel = (orderRef) => (dispatch) => {
  dispatch(requestCancel({ orderRef }));
  bankidService.cancel(orderRef).then(
    (tokenResponse) => {
      dispatch(cancelSuccess(tokenResponse));
    },
    (error) => {
      dispatch(cancelFailure(error.message));
    }
  );
};

const bankIdAuthenticate = () => (dispatch, getState) => {
  const authLoading = getLoadingByType(getState(), "bankIdAuth");
  if (authLoading) {
    console.log("Cannot start a new BankID auth when one is already running");
    return;
  }

  /* let personalNumber = getFormValues("loginForm")(getState());
  if (!personalNumber) return;
  personalNumber = personalNumber.login; */
  dispatch(requestAuth());
  bankidService
    .authenticate()
    .then((response) => {
      dispatch(authSuccess(response));
      history.push(`${BANKID_COLLECT}/${response.orderRef}`);
    })
    .catch((err) => {
      dispatch(authFailure());
      dispatch(snackbarActions.openSnackbar("failure", err.message));
    });
};

const openBankidApplication = () => (dispatch, getState) => {
  const pending = isOpenBankIdPending(getState());

  if (pending) {
    console.log("Cannot open application while it's already opening");
    return;
  }
  dispatch(openBankIdPending());
  bankidService
    .authenticate()
    .then((response) => {
      dispatch(authSuccess(response));
      dispatch(openBankIdSuccess(response.autoStartToken));
      history.push(`${BANKID_REDIRECT}/${response.orderRef}`);
    })
    .catch((err) => {
      dispatch(authFailure());
      dispatch(openBankIdFailure());
      dispatch(snackbarActions.openSnackbar("failure", err.message));
    });
};

function waitForBankIdLogin(orderRef, dispatch) {
  return new Promise(async (resolve, reject) => {
    /**
     * As of 2019-05-24 you have aprox. 5 1/2 minutes (160 iterations) to login
     * until the bankid service will abort. This is a safety circuit breaker
     * not to get stuck in an infinite loop
     */
    const MAX_COLLECT_TRIES = 170;
    const POLLING_INTERVAL_MS = 2000;
    let authenticationComplete = false;

    while (!authenticationComplete) {
      for (let i = 0; i < MAX_COLLECT_TRIES; i++) {
        dispatch(requestCollect({ orderRef }));
        try {
          const response = await bankidService.collect(orderRef);
          dispatch(saveQrCode(response.qrCode));
          const { status, hintCode } = response;
          if (status === "complete") {
            authenticationComplete = true;
            dispatch(snackbarActions.closeSnackbar());
            dispatch(collectSuccess(response));
            resolve(response);
            return;
          }
          if (hintCode === "startFailed") {
            reject();
            return;
          }
          if (status === "failed") {
            reject(new Error("Kunde ej logga in, var vänlig försök igen."));
            return;
          }
        } catch (error) {
          if (error instanceof TypeError) {
            reject();
            return;
          }
          dispatch(collectFailure(error));
          bankidService.cancel(orderRef);
          reject(new Error("Något gick fel, var vänlig försök igen."));
          return;
        }
        await timeout(POLLING_INTERVAL_MS);
      }
      reject("Vänligen ladda om sidan för att logga in.");
    }
  });
}

const bankIdWaitForCollect = (orderRef) => (dispatch) => {
  waitForBankIdLogin(orderRef, dispatch)
    .then(async (response) => {
      const loginResponse = await loginService.login(response.token);
      setTokenLocalStorage(loginResponse.token);
      dispatch(loginSuccess());
      history.push(HOME);
    })
    .catch((err) => {
      dispatch(authFailure());
      if (err && err.message) {
        dispatch(snackbarActions.openSnackbar("failure", err.message));
      }
      history.push(LOGIN);
    });
};

const bankIdQrCodeAuthenticate = () => (dispatch, getState) => {
  const authLoading = getLoadingByType(getState(), "bankIDQRcodePolling");
  if (authLoading) {
    console.log("Cannot start a new BankID auth when one is already running");
    return;
  }
  const isLoggedIn = isUserLoggedIn(getState(), "loggedIn");

  if (isLoggedIn) {
    console.log("Cannot start a new BankID auth when one is already logged in");
    return;
  }

  bankidService
    .authenticate()
    .then((response) => {
      dispatch(requestQrCodeAuth({ ...response }));
      bankIdWaitForCollect(response.orderRef)(dispatch);
      dispatch(authQrCodeSuccess(response));
    })
    .catch((err) => {
      dispatch(authQrCodeFailure());
      dispatch(snackbarActions.openSnackbar("failure", err.message));
    });
};

export default {
  bankIdAuthenticate,
  bankIdQrCodeAuthenticate,
  cancel,
  loginSuccess,
  bankIdWaitForCollect,
  openBankidApplication,
};
