import React from "react";
import PropTypes from "prop-types";
import pdfIcon from "../../assets/images/pdf_ikon.png";
import Button from "../helper/Button";
import lang from "../../helpers/lang-constants";

const language = lang.customer.components.insurance.insurance_card;

function PDFListHelper(props) {
  const {
    currentPDF,
    upcomingPDF,
    dateActive,
    fetchInsurancePolicy,
    insuranceNumber,
  } = props;

  const today = new Date();
  const activeDate = dateActive ? new Date(dateActive) : null;

  let currentTitle = "";
  let upcomingTitle = "";

  if (currentPDF && upcomingPDF) {
    if (activeDate && activeDate >= today) {
      currentTitle = "Nuvarande";
      upcomingTitle = "Kommande";
    } else if (activeDate && activeDate < today) {
      currentTitle = "Tidigare";
      upcomingTitle = "Nuvarande";
    } else if (currentPDF) {
      currentTitle = "Nuvarande";
    } else if (upcomingPDF && (!activeDate || activeDate > today)) {
      upcomingTitle = "Nuvarande";
    }
  }

  return (
    <div className="insurance-letters_wrapper">
      {currentPDF && (
        <>
          <p>{currentTitle}</p>
          <div className="insurance-letters_content">
            <div className="insurance-letters_image-container">
              <img className="pdf-logo" src={pdfIcon} alt="pdfLogo" />
            </div>
            <Button
              type="button"
              onClick={() => fetchInsurancePolicy(insuranceNumber, currentPDF)}
              className="insurnace-letters_link"
              id="current"
            >
              {language.insurance_letters_text}
            </Button>
          </div>
        </>
      )}
      {upcomingPDF && (
        <>
          <p>{upcomingTitle}</p>
          <div className="insurance-letters_content">
            <div className="insurance-letters_image-container">
              <img className="pdf-logo" src={pdfIcon} alt="pdfLogo" />
            </div>
            <Button
              type="button"
              onClick={() => fetchInsurancePolicy(insuranceNumber, upcomingPDF)}
              className="insurnace-letters_link"
            >
              {language.insurance_letters_text}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

PDFListHelper.propTypes = {
  currentPDF: PropTypes.string.isRequired,
  upcomingPDF: PropTypes.string.isRequired,
  dateActive: PropTypes.string.isRequired,
  fetchInsurancePolicy: PropTypes.func.isRequired,
  insuranceNumber: PropTypes.string.isRequired,
};

export default PDFListHelper;
