import React, { useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import QRCode from "qrcode.react";
import { history } from "../../../helpers";
import lang from "../../../helpers/lang-constants";
import { authSelectors } from "../../../selectors";
import { isPhone } from "../../../helpers/device";
import { routesConstants } from "../../../constants";
import "./ab_error.scss";
import abErrorSelector from "../../../selectors/abError.selector";
import { formatISODate } from "../../../helpers/dateConverter";

const { LOGIN } = routesConstants;
const { getLoading, getQrCode } = authSelectors;
const language = lang.customer.components.login.bankid.bankid_login_form;
const { selectError } = abErrorSelector;
function BankIdLoginForm({
  fetchErrorStatusOnMount,
  isLoading,
  selectError,
  requestBankIdQrCodeAuth,
  getQrCodeValue,
}) {
  useEffect(() => {
    fetchErrorStatusOnMount && fetchErrorStatusOnMount();
    if (requestBankIdQrCodeAuth) {
      requestBankIdQrCodeAuth();
    }
  }, []);

  function splitMessage(message) {
    const [title, description] = message.split(";");
    return (
      <>
        <h1>{title}</h1>
        <p>{description}</p>
      </>
    );
  }
  const errorMessages = selectError?.communicationTexts;

  const getErrorMessages = () => {
    return errorMessages?.map((message) => {
      return (
        <div className="ab-error-divider">
          <span>{splitMessage(message?.text)}</span>
          <p>
            OBS! Ovan information beräknas vara gällande till <br />
            {formatISODate(message?.endTime)}
          </p>
        </div>
      );
    });
  };

  return (
    <div className="content flex column">
      <span>
        {language.description_text_mobile}
        <br />
        <br />
      </span>
      <form
        text="Logga in"
        className="login-form flex column align-center justify-center"
      >
        <div className="login-form-content" />
        <div className="flex column justify-center align-center">
          {getQrCodeValue && !isLoading && (
            <div className="qr-code">
              <QRCode value={getQrCodeValue} />
            </div>
          )}
          {isPhone() && (
            <a onClick={() => history.push(LOGIN)}>
              {language.cancel_button.text}
            </a>
          )}
        </div>
      </form>
      {errorMessages && errorMessages.length > 0 && (
        <div className="ab-error-phone">{getErrorMessages()}</div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const getQrCodeValue = getQrCode(state);

  return {
    isLoading: getLoading(state).get("bankIdAuth", false),
    selectError: selectError(state),
    getQrCodeValue,
  };
};

export default reduxForm({
  form: "loginForm",
})(connect(mapStateToProps)(BankIdLoginForm));
